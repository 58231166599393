<template>
  <FormControl
    :title="title"
    :sub-title="subTitle"
    :label="label"
    :block="block"
    :disabled="disabled"
    :identifier="identifier">
    <div
      v-if="$slots.prefix"
      class="w-full flex items-center px-4 pointer-events-none overflow-hidden">
      <slot name="prefix" />
    </div>
    <div class="flex gap-2 items-center">
      <div
        class="text-area-wrapper w-80/100 border-1 flex flex-col items-start relative rounded-4 focus:border-primary-700"
        :class="inputClasses">
        <div class="flex w-full">
          <textarea
            ref="target"
            v-bind="props"
            v-model="model"
            :placeholder="placeholder"
            :disabled="disabled || !editable"
            :tabindex="targetIsVisible ? 0 : -1"
            :rows="rows"
            class="grow border-0 bg-transparent p-0 resize-none caret-primary-700"/>
          <div
            v-if="$slots.suffix"
            class="w-10 flex items-center pl-3 pointer-events-none overflow-hidden">
            <slot name="suffix" />
          </div>
        </div>
      </div>
      <div
        v-if="$slots.tip"
        class="mt-2 text-sm text-secondary-500 dark:text-secondary-400">
        <slot name="tip" />
      </div>
    </div>
  </FormControl>
</template>
<script lang="ts" setup>
import type { InputSize, InputVariant } from '@/components/Input/types';
import { useInputClasses } from '@/components/Input/composables/useInputClasses';
import { ref, toRefs, watch, nextTick } from 'vue';
import { useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useElementVisibility, useEventListener } from '@vueuse/core';

interface IProps {
  title?: string;
  subTitle?: string;
  label?: string;
  disabled?: boolean;
  size?: InputSize;
  variant?: InputVariant;
  placeholder?: string;
  block?: string;
  identifier?: string;
  modelValue: string;
  rows?: number;
  editable?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  title: '',
  subTitle: '',
  label: '',
  disabled: false,
  size: 'md',
  variant: 'default',
  placeholder: 'התשובה שלי',
  block: '',
  identifier: '',
  modelValue: () => '',
  rows: 3,
  editable: true,
});

defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue');
const { inputClasses } = useInputClasses(toRefs(props));
const target = ref();
const targetIsVisible = useElementVisibility(target);

const adjustHeight = () => {
  if (target.value) {
    target.value.style.height = 'auto';
    if (target.value.scrollHeight) {
      target.value.style.height = target.value.scrollHeight + 'px';
    }
  }
};

useEventListener(window, 'resize', () => {
  adjustHeight();
});

watch(
  () => model.value,
  () => {
    nextTick(adjustHeight);
  },
  { immediate: true },
);
</script>
